main {
  background-color: #e5e5e5;
  flex: 1 1;
}

.test {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
