body {
  margin: 0;
  padding: 0;
  font-family: Sansation, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: black;
  text-decoration: none;
}

button {
  cursor: pointer;
  border-width: 0;
  font-family: Sansation;
}

input {
  border-width: 0;
}

svg {
  fill: currentColor;
}

@font-face {
  font-family: "Sansation";
  src: url(./fonts/Sansation-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Sansation-Bold";
  src: url(./fonts/Sansation-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Sansation-BoldItalic";
  src: url(./fonts/Sansation-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Sansation-Light";
  src: url(./fonts/Sansation-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Sansation-LightItalic";
  src: url(./fonts/Sansation-LightItalic.ttf) format("truetype");
}
